<template>
  <div id="app">
    <div id="blueRow2">
      <Header />
      <TopStats :stats="stats" />
    </div>
    <!-- PINNED NODES -->
    <div v-if="pinned.length > 0">
      <div id="status_table" class="container-fluid">
        <div class="card card-q">
          <NodesTable
            :isPinning="true"
            v-on:pin="pinNode($event)"
            :nodes="pinned"
          />
        </div>
      </div>
    </div>
    <!-- ALL NODES -->
    <div v-if="!isLoading">
      <div id="status_table" class="container-fluid">
        <div class="card card-q">
          <div class="choose-container">
            <select v-model="perPage" class="form-select select-nodes">
              <option value="15">Show 15 nodes per page</option>
              <option value="50">Show 50 nodes per page</option>
              <option value="9999999">Show all nodes</option>
            </select>
            <input
              type="text"
              v-model="search"
              class="search-input form-control"
              placeholder="Search for a node.."
            />
          </div>
          <div v-if="nodes.length > 0">
            <NodesTable
              :nodes="nodes[page]"
              :pages="pages"
              :page="page"
              v-on:update:page="page = $event"
              v-on:pin="pinNode($event)"
            />
          </div>
          <div v-if="nodes.length === 0" class="text-center">
            No data to show.
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="container-fluid">
      <div class="card card-q text-center">Loading please wait...</div>
    </div>
    <div id="status_table" v-if="showAbout" class="container-fluid">
      <h3>About Quadrans Network Status</h3>
      <p>
        This page does not represent the entire state of the Quadrans blockchain
        network. Listing a node on this page is a voluntary process, mandatory
        for
        <a href="https://quadrans.io/mining" target="_blank"
          >Miner nodes or Masternodes</a
        >.
      </p>
      <h4>Legend</h4>
      <table class="table table-striped mb-1">
        <tbody>
          <tr class="text-info bold">
            <td style="width: 60px">Icon</td>
            <td style="width: 180px" class="d-none d-md-table-cell">Value</td>
            <td>Description</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Pin your nodes" class="far fa-check-circle"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              <i title="Pin your nodes" class="far fa-check-circle"></i> /
              <i title="Pin your nodes" class="far fa-circle"></i>
            </td>
            <td>Pinned / Unpinned node</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Node type" class="fas fa-crown"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              <i title="Node type" class="fas fa-crown"></i> /
              <i class="fas fa-network-wired"></i>
            </td>
            <td>Masternode / Node</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Node name" class="fas fa-terminal"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              Quadrans Foundation
            </td>
            <td>Node name</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Node version" class="fas fa-laptop"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              gqdc/v1.5.2-stable-1528b791
            </td>
            <td>Go Quadrans client version number</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="System type" class="fas fa-keyboard"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              linux-amd64/go1.12
            </td>
            <td>Operating System and CPU Architecture</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Node latency" class="fas fa-stopwatch"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">50 ms</td>
            <td>Node latency in milliseconds</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Peers" class="fas fa-users"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">5</td>
            <td>Number of peers connected to the node</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Last block" class="fas fa-cubes"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              #18050000
            </td>
            <td>Last block sent to the Network Status</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Last refresh" class="far fa-clock"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">10m ago</td>
            <td>Last sync with the Network Status</td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="% Checkpoints" class="fa fa-flag-checkered"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">90 %</td>
            <td>
              Percentage of checkpoints on last 30 days (uptime of the node)
            </td>
          </tr>
          <tr>
            <td class="text-info text-center" style="width: 60px">
              <i title="Country" class="fas fa-flag"></i>
            </td>
            <td style="width: 180px" class="d-none d-md-table-cell">
              Rome, Roman Empire
            </td>
            <td>
              Geolocation of the node based on IP (could not be propely
              determined)
            </td>
          </tr>
        </tbody>
      </table>
      <h4 class="mt-3">Notes</h4>
      <p>
        The information in the Quadrans Network Status
        <b>are not in real time</b>.
      </p>
      <p>
        Lines in the Network Status could be in different colours that
        represents the <b>number of connected peers</b> to a node: 0-1
        <span style="color: red">red</span>, 2-4
        <span style="color: orange">orange</span>, 5+
        <span style="color: green">green</span>.
      </p>
      <p>
        Nodes not fully synced will shows #0 blocks and will be in black color
        <b>until the end</b> of the syncronization process.
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import TopStats from "./components/TopStats.vue";
import NodesTable from "./components/NodesTable.vue";
import Footer from "./components/Footer.vue";
const axios = require("axios");
// Appending dynamic stylesheet
const link = document.createElement("link");
link.rel = "stylesheet";
link.type = "text/css";
link.href =
  "/css/styles." + process.env.VUE_APP_ENVIRONMENT.toLowerCase() + ".css";
document.getElementsByTagName("HEAD")[0].appendChild(link);

export default {
  name: "App",
  components: {
    TopStats,
    NodesTable,
    Header,
    Footer,
  },
  data() {
    return {
      pinned: [],
      nodes: [],
      stats: [],
      perPage: 15,
      checkpoints: {},
      page: 0,
      pages: 0,
      axios: axios,
      nodesURL:
        process.env["VUE_APP_JSON_NODES_" + process.env.VUE_APP_ENVIRONMENT],
      checkpointsURL:
        process.env[
          "VUE_APP_JSON_CHECKPOINTS_" + process.env.VUE_APP_ENVIRONMENT
        ],
      isLoading: true,
      search: "",
      showAbout:
        process.env.VUE_APP_ENVIRONMENT !== undefined &&
        process.env.VUE_APP_ENVIRONMENT === "MAINNET",
    };
  },
  async mounted() {
    const app = this;
    await app.refreshNodes();
    app.isLoading = false;
    if (localStorage.getItem("pinned") !== null) {
      try {
        app.pinned = JSON.parse(localStorage.getItem("pinned"));
      } catch (e) {
        localStorage.setItem("pinned", null);
      }
    }
    setInterval(function () {
      app.refreshNodes();
    }, 5000);
  },
  watch: {
    perPage() {
      const app = this;
      app.page = 0;
      app.refreshNodes();
    },
    search() {
      const app = this;
      if (app.search.length > 0) {
        app.page = 0;
        app.refreshNodes();
      }
    },
  },
  methods: {
    async refreshNodes() {
      const app = this;
      try {
        const checkpoints = await axios.get(app.checkpointsURL);
        app.checkpoints = checkpoints.data;
        const stats = await axios.get(app.nodesURL);
        app.stats = stats.data;
        let filtered = [];
        let pinned = [];
        let updated_pinned = [];
        if (app.pinned.length > 0) {
          for (let k in app.pinned) {
            pinned.push(app.pinned[k].id);
          }
        }
        for (let k in stats.data) {
          let check = stats.data[k];
          const checkpoints = app.checkpoints[check.id];
          if (
            checkpoints !== undefined &&
            checkpoints.passed !== undefined &&
            checkpoints.total !== undefined
          ) {
            check.checkpoints = parseFloat(
              ((checkpoints.passed / checkpoints.total) * 100).toFixed(2)
            );
          } else {
            check.checkpoints = "0.00";
          }
          if (pinned.length > 0 && pinned.indexOf(check.id) !== -1) {
            updated_pinned.push(check);
          }
          if (app.search.length > 0) {
            if (
              check.id.toLowerCase().indexOf(app.search.toLowerCase()) !== -1 ||
              check.node_type
                .toLowerCase()
                .indexOf(app.search.toLowerCase()) !== -1 ||
              check.geo.country
                .toLowerCase()
                .indexOf(app.search.toLowerCase()) !== -1 ||
              check.geo.city.toLowerCase().indexOf(app.search.toLowerCase()) !==
                -1
            ) {
              if (pinned.length > 0 && pinned.indexOf(check.id) === -1) {
                filtered.push(check);
              } else if (pinned.length === 0) {
                filtered.push(check);
              }
            }
          } else if (pinned.length > 0 && pinned.indexOf(check.id) === -1) {
            filtered.push(check);
          } else if (pinned.length === 0) {
            filtered.push(check);
          }
        }
        app.pinned = updated_pinned;
        app.nodes = filtered.reduce((chunked, item, index) => {
          const chunkIndex = Math.floor(index / app.perPage);
          if (!chunked[chunkIndex]) {
            chunked[chunkIndex] = [];
          }
          chunked[chunkIndex].push(item);
          return chunked;
        }, []);
        app.pages = app.nodes.length;
      } catch (e) {
        console.log(e);
        // app.refreshNodes();
      }
    },
    pinNode(node) {
      const app = this;
      let temporary = [];
      let found = false;
      for (let k in app.pinned) {
        if (app.pinned[k].id === node) {
          found = true;
        } else {
          temporary.push(app.pinned[k]);
        }
      }
      if (!found) {
        for (let k in app.stats) {
          if (app.stats[k].id === node) {
            temporary.push(app.stats[k]);
          }
        }
      }
      app.pinned = temporary;
      app.refreshNodes();
      localStorage.setItem("pinned", JSON.stringify(app.pinned));
    },
  },
};
</script>
