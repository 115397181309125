<template>
  <footer class="fullwidth ng-scope">
    <div id="footerMiddle" class="pt-4">
      <div style="overflow: hidden" class="container-fluid">
        <div class="row">
          <div class="col-12 my-1">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3 text-center">
                <a href="https://quadrans.io" target="_blank"
                  ><img
                    src="https://quadrans.io/assets/brand/blockchain/logo_quadrans_totalWhite.svg"
                    alt="Quadrans Foundation"
                    height="43"
                /></a>
              </div>
              <div class="col-12 col-lg-6 mb-3 text-center">
                <a href="https://twitter.com/quadrans" title="Twitter" target="_blank">
                    <div class="circle-violet inline-block text-center">
                        <i class="fab fa-twitter" aria-hidden="true"></i>
                    </div>
                </a>
                <a href="https://t.me/quadrans" title="Telegram" target="_blank">
                    <div class="circle-violet inline-block text-center">
                        <i class="fab fa-telegram-plane" aria-hidden="true"></i>
                    </div>
                </a>
                <a href="https://github.com/quadrans/" title="GitHub" target="_blank">
                  <div class="circle-violet inline-block text-center">
                    <i class="fab fa-github" aria-hidden="true"></i>
                    </div>
                </a>
                <br class="d-none d-lg-block">
                <a href="https://reddit.com/r/quadrans" title="Reddit" target="_blank">
                    <div class="circle-violet inline-block text-center">
                        <i class="fab fa-reddit-alien" aria-hidden="true"></i>
                    </div>
                </a>
                <a href="https://discord.gg/KTp8J2X2q6" title="Discord" target="_blank">
                    <div class="circle-violet inline-block text-center">
                        <i class="fab fa-discord" aria-hidden="true"></i>
                    </div>
                </a>
                <a href="https://instagram.com/quadransblockchain" title="Instagram" target="_blank">
                    <div class="circle-violet inline-block text-center">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                    </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footerBottom">
      <div style="overflow: hidden; padding: 10px" class="container-fluid">
        <div class="row">
          <div class="col">
              <p class="text-center my-3" style="line-height: 1.1rem !important;">
                <small class="text-light">Copyright © 2018-{{ new Date().getFullYear() }} 
                <b>Quadrans Blockchain</b><br>
                The "Q" symbol is a registered trademark of Quadrans Foundation | e-mail: <a>fondazione@quadrans.io</a></small>
              </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
