<template>
  <div>
    <div id="change-navbar"></div>
    <div id="navigation">
      <nav class="navbar navbar-dark navbar-expand-lg py-0 fixed-top">
        <a class="navbar-brand active" href="/">
          <div id="logo">
            <img
              src="https://quadrans.io/assets/brand/blockchain/logo_quadrans_white.svg"
              alt="Quadrans Blockchain"
              class="logo"
            />
          </div>
        </a>
        <!-- START NAVBAR LINKS -->
        <div class="navbar ml-auto my-auto" id="navbarSupportedContent">
          <ul class="navbar-nav mr-lg-3 ml-auto mr-auto">
            <li class="nav-item text-center">
              <a class="nav-link acidGreen" href="https://quadrans.io/tools"
                >Tools <i class="ml-2 fas fa-external-link-alt"></i
              ></a>
            </li>
            <li class="nav-item text-center d-none d-lg-block">
              <a class="nav-link acidGreen" href="https://quadrans.io/get-started"
                >Mining <i class="ml-2 fas fa-external-link-alt"></i
              ></a>
            </li>
          </ul>
        </div>
        <!-- END NAVBAR LINKS -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>
