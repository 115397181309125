<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-none d-lg-block col-lg-12 col-xl-5">
        <l-map style="height: 330px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            v-for="node in stats"
            v-bind:key="node.id"
            :lat-lng="node.geo.ll"
            :icon="icon"
          >
            <l-popup>{{ node.id }}</l-popup>
          </l-marker>
        </l-map>
      </div>
      <div class="col-12 col-lg-12 col-xl-7">
        <div class="row">
          <div class="col-12 col-xl-4">
            <div class="row">
              <div class="col-6 col-xl-12 mt-0">
                <h4 class="status-column bold acidGreen">Best Block</h4>
                <h2 class="text-white bold mb-4">#{{ lastBlock }}</h2>
              </div>
              <div class="col-6 col-xl-12 mt-0">
                <h4 class="status-column bold acidGreen">Uptime</h4>
                <h2 class="text-white mb-4">100 %</h2>
              </div>
              <div class="col-6 col-xl-12 mt-0">
                <h4 class="status-column bold acidGreen">Gas Limit</h4>
                <h2 class="text-white mb-4">8000000 gas</h2>
              </div>
              <div class="col-6 col-xl-12 mt-0">
                <h4 class="status-column bold acidGreen">Gas Price</h4>
                <h2 class="text-white mb-4">{{ gasPrice }} gwei</h2>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-8">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="card card-q card-q-status my-auto">
                  <h1 class="text-white bold">
                    {{ active }}<small>/{{ stats.length }}</small>
                  </h1>
                  <h4 class="status-box acidGreen">Active nodes</h4>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="card card-q card-q-status">
                  <h1 class="text-white bold">{{ timePassed }}s ago</h1>
                  <h4 class="status-box acidGreen">Last block</h4>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="card card-q card-q-status">
                  <h1 class="text-white bold">{{ avgBlockTime }}s</h1>
                  <h4 class="status-box acidGreen">Block time</h4>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="card card-q card-q-status">
                  <h1 class="text-white bold">100%</h1>
                  <h4 class="status-box acidGreen">Uptime</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const L = require('leaflet')
export default {
  name: "TopStats",
  props: ["stats"],
  data() {
    return {
      active: 0,
      gasPrice: 0,
      lastBlock: 0,
      timePassed: 0,
      avgBlockTime: 0,
      times: [],
      axios: axios,
      rpcUrl: process.env['VUE_APP_RPC_URL_' + process.env.VUE_APP_ENVIRONMENT],
      icon: L.icon({
        iconUrl: '/img/pin.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      map: {},
      url: "https://api.mapbox.com/styles/v1/yomiweb3/ckxiqukmi0h0314tivj5pi5y0/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieW9taXdlYjMiLCJhIjoiY2t4aXF0ZjNqMWZ6bzJ3bnAzdGZqOHlqbyJ9.NkyEpyIIs7IOSyJStvZnKg",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 1,
      center: [51.505, -0.159]
    };
  },
  watch: {
    stats() {
      const app = this;
      app.active = 0;
      if (app.stats !== undefined && app.stats.length > 0) {
        for (let k in app.stats) {
          const node = app.stats[k];
          if (app.timeSince(node.last_signal)) {
            app.active++;
          }
        }
      }
    },
  },
  mounted() {
    const app = this;
    app.getGasPrice();
    app.getLastBlock();
    setInterval(function () {
      app.getGasPrice();
      app.getLastBlock();
    }, 5000);
    setInterval(function () {
      app.timePassed++;
    }, 1000);
  },
  methods: {
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return false;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return false;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return false;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return false;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return true;
      }
      return true;
    },
    async getGasPrice() {
      const app = this;
      const response = await app.axios.post(app.rpcUrl, {
        jsonrpc: "2.0",
        method: "eth_gasPrice",
        params: [],
        id: new Date().getTime(),
      });
      app.gasPrice = parseInt(response.data.result, 16) / 1000000;
    },
    async getLastBlock() {
      const app = this;
      const response = await app.axios.post(app.rpcUrl, {
        jsonrpc: "2.0",
        method: "eth_blockNumber",
        params: [],
        id: new Date().getTime(),
      });
      if (parseInt(response.data.result, 16) !== app.lastBlock) {
        app.times.push(app.timePassed);
        let sum = 0;
        for (let k in app.times) {
          sum += app.times[k];
        }
        app.avgBlockTime = parseInt(sum / app.times.length);
        app.timePassed = 0;
      }
      app.lastBlock = parseInt(response.data.result, 16);
    },
  },
};
</script>
