<template>
  <div>
    <h2 v-if="isPinning !== undefined">Your Pinned nodes</h2>
    <h2 v-if="isPinning === undefined">All network nodes</h2>

    <table class="table table-striped mb-1">
      <thead>
        <tr class="text-info">
          <th class="th-nodecheck">
            <i title="Pin your nodes" class="far fa-check-circle"></i>
          </th>
          <th class="th-nodetype">
            <i title="Node type" class="fas fa-crown"></i>
          </th>
          <th class="th-nodename">
            <i title="Node name" class="fas fa-terminal"></i>
          </th>
          <th class="d-none d-xl-table-cell th-nodeversion">
            <i title="Node version" class="fas fa-laptop"></i>
          </th>
          <th class="d-none d-xl-table-cell th-system">
            <i title="System type" class="fas fa-keyboard"></i>
          </th>
          <th class="d-none d-xl-table-cell th-latency">
            <i title="Node latency" class="fas fa-stopwatch"></i>
          </th>
          <th class="th-peers"><i title="Peers" class="fas fa-users"></i></th>
          <th class="d-none d-md-table-cell th-lastblock">
            <i title="Last block" class="fas fa-cubes"></i>
          </th>
          <th class="d-none d-xl-table-cell th-peerRefresh">
            <i title="Last refresh" class="far fa-clock"></i>
          </th>
          <th class="th-uptime">
            <i title="% Checkpoints" class="fa fa-flag-checkered"></i>
          </th>
          <th class="d-none d-md-table-cell th-geolocal">
            <i title="Country" class="fas fa-flag"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="node in nodes"
          v-bind:key="node.id"
          v-bind:class="{
            'text-success':
              node.stats !== undefined &&
              node.stats.status !== undefined &&
              node.stats.status !== null &&
              nodeStatus(node.stats.status.peers) === 'green',
            'text-danger':
              node.stats !== undefined &&
              node.stats.status !== undefined &&
              node.stats.status !== null &&
              nodeStatus(node.stats.status.peers) === 'red',
            'text-warning':
              node.stats !== undefined &&
              node.stats.status !== undefined &&
              node.stats.status !== null &&
              nodeStatus(node.stats.status.peers) === 'orange',
          }"
        >
          <td class="td-nodecheck">
            <i
              v-if="isPinning === undefined"
              v-on:click="$emit('pin', node.id)"
              class="far fa-circle hover"
            ></i>
            <i
              v-if="isPinning !== undefined"
              v-on:click="$emit('pin', node.id)"
              class="far fa-check-circle hover"
            ></i>
          </td>
          <td class="td-nodetype text-gray">
            <span
              v-if="
                node.stats !== undefined &&
                node.stats.status !== undefined &&
                node.stats.status !== null &&
                node.stats.status.mining !== undefined
              "
            >
              <i
                v-if="!node.stats.status.mining"
                class="fas fa-network-wired"
              ></i>
              <i v-if="node.stats.status.mining" class="fas fa-crown"></i>
            </span>
            <span v-else>
              <i class="fas fa-network-wired"></i>
            </span>
          </td>
          <td :title="node.id" class="td-nodename">
            {{ node.id }}
          </td>
          <td class="td-nodeversion d-none d-xl-table-cell">
            <div class="node-info text-truncate">
              {{
                node.node_type.split("/")[0] +
                "/" +
                node.node_type.split("/")[1]
              }}
            </div>
          </td>
          <td class="td-system d-none d-xl-table-cell">
            <div class="node-info text-truncate">
              {{
                node.node_type.split("/")[2] +
                "/" +
                node.node_type.split("/")[3]
              }}
            </div>
          </td>
          <td class="td-latency d-none d-xl-table-cell">
            {{ node.latency }} ms
          </td>
          <td class="td-peers">
            <span
              v-if="
                node.stats !== undefined &&
                node.stats.status !== undefined &&
                node.stats.status !== null
              "
            >
              {{ node.stats.status.peers }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="td-lastblock d-none d-md-table-cell">
            <span
              v-if="
                node.last_block !== undefined &&
                node.last_block.number !== undefined
              "
            >
              #{{ node.last_block.number }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="th-peerRefresh d-none d-xl-table-cell">
            {{ timeSince(new Date(node.last_signal)) }} ago
          </td>
          <td class="td-uptime">{{ node.checkpoints }} %</td>
          <td class="td-geolocal d-none d-md-table-cell">
            {{ node.geo.city }}, {{ node.geo.country }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination" v-if="isPinning === undefined">
      <div
        class="prev"
        v-if="page > 0"
        v-on:click="$emit('update:page', page - 1)"
      >
        <i style="font-size: 20px" class="fas fa-arrow-left"></i>
      </div>
      <div>
        <span v-if="pages > 1">Page: {{ page + 1 }} / {{ pages }}</span
        ><span v-if="pages === 1 && nodes !== undefined"
          >Showing {{ nodes.length }} nodes</span
        >
      </div>
      <div
        class="next"
        v-if="page + 1 !== pages"
        v-on:click="$emit('update:page', page + 1)"
      >
        <i style="font-size: 20px" class="fas fa-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NodesTable",
  props: ["nodes", "pages", "page", "isPinning"],
  methods: {
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " y";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " m";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " d";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " h";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + "m";
      }
      return Math.floor(seconds) + " s";
    },
    nodeStatus(peers) {
      if (peers !== undefined && peers !== null) {
        if (peers <= 1) {
          return "red";
        } else if (peers > 1 && peers <= 4) {
          return "orange";
        } else if (peers > 4) {
          return "green";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
