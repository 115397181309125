var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPinning !== undefined)?_c('h2',[_vm._v("Your Pinned nodes")]):_vm._e(),(_vm.isPinning === undefined)?_c('h2',[_vm._v("All network nodes")]):_vm._e(),_c('table',{staticClass:"table table-striped mb-1"},[_vm._m(0),_c('tbody',_vm._l((_vm.nodes),function(node){return _c('tr',{key:node.id,class:{
          'text-success':
            node.stats !== undefined &&
            node.stats.status !== undefined &&
            node.stats.status !== null &&
            _vm.nodeStatus(node.stats.status.peers) === 'green',
          'text-danger':
            node.stats !== undefined &&
            node.stats.status !== undefined &&
            node.stats.status !== null &&
            _vm.nodeStatus(node.stats.status.peers) === 'red',
          'text-warning':
            node.stats !== undefined &&
            node.stats.status !== undefined &&
            node.stats.status !== null &&
            _vm.nodeStatus(node.stats.status.peers) === 'orange',
        }},[_c('td',{staticClass:"td-nodecheck"},[(_vm.isPinning === undefined)?_c('i',{staticClass:"far fa-circle hover",on:{"click":function($event){return _vm.$emit('pin', node.id)}}}):_vm._e(),(_vm.isPinning !== undefined)?_c('i',{staticClass:"far fa-check-circle hover",on:{"click":function($event){return _vm.$emit('pin', node.id)}}}):_vm._e()]),_c('td',{staticClass:"td-nodetype text-gray"},[(
              node.stats !== undefined &&
              node.stats.status !== undefined &&
              node.stats.status !== null &&
              node.stats.status.mining !== undefined
            )?_c('span',[(!node.stats.status.mining)?_c('i',{staticClass:"fas fa-network-wired"}):_vm._e(),(node.stats.status.mining)?_c('i',{staticClass:"fas fa-crown"}):_vm._e()]):_c('span',[_c('i',{staticClass:"fas fa-network-wired"})])]),_c('td',{staticClass:"td-nodename",attrs:{"title":node.id}},[_vm._v(" "+_vm._s(node.id)+" ")]),_c('td',{staticClass:"td-nodeversion d-none d-xl-table-cell"},[_c('div',{staticClass:"node-info text-truncate"},[_vm._v(" "+_vm._s(node.node_type.split("/")[0] + "/" + node.node_type.split("/")[1])+" ")])]),_c('td',{staticClass:"td-system d-none d-xl-table-cell"},[_c('div',{staticClass:"node-info text-truncate"},[_vm._v(" "+_vm._s(node.node_type.split("/")[2] + "/" + node.node_type.split("/")[3])+" ")])]),_c('td',{staticClass:"td-latency d-none d-xl-table-cell"},[_vm._v(" "+_vm._s(node.latency)+" ms ")]),_c('td',{staticClass:"td-peers"},[(
              node.stats !== undefined &&
              node.stats.status !== undefined &&
              node.stats.status !== null
            )?_c('span',[_vm._v(" "+_vm._s(node.stats.status.peers)+" ")]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"td-lastblock d-none d-md-table-cell"},[(
              node.last_block !== undefined &&
              node.last_block.number !== undefined
            )?_c('span',[_vm._v(" #"+_vm._s(node.last_block.number)+" ")]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"th-peerRefresh d-none d-xl-table-cell"},[_vm._v(" "+_vm._s(_vm.timeSince(new Date(node.last_signal)))+" ago ")]),_c('td',{staticClass:"td-uptime"},[_vm._v(_vm._s(node.checkpoints)+" %")]),_c('td',{staticClass:"td-geolocal d-none d-md-table-cell"},[_vm._v(" "+_vm._s(node.geo.city)+", "+_vm._s(node.geo.country)+" ")])])}),0)]),(_vm.isPinning === undefined)?_c('div',{staticClass:"pagination"},[(_vm.page > 0)?_c('div',{staticClass:"prev",on:{"click":function($event){return _vm.$emit('update:page', _vm.page - 1)}}},[_c('i',{staticClass:"fas fa-arrow-left",staticStyle:{"font-size":"20px"}})]):_vm._e(),_c('div',[(_vm.pages > 1)?_c('span',[_vm._v("Page: "+_vm._s(_vm.page + 1)+" / "+_vm._s(_vm.pages))]):_vm._e(),(_vm.pages === 1 && _vm.nodes !== undefined)?_c('span',[_vm._v("Showing "+_vm._s(_vm.nodes.length)+" nodes")]):_vm._e()]),(_vm.page + 1 !== _vm.pages)?_c('div',{staticClass:"next",on:{"click":function($event){return _vm.$emit('update:page', _vm.page + 1)}}},[_c('i',{staticClass:"fas fa-arrow-right",staticStyle:{"font-size":"20px"}})]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"text-info"},[_c('th',{staticClass:"th-nodecheck"},[_c('i',{staticClass:"far fa-check-circle",attrs:{"title":"Pin your nodes"}})]),_c('th',{staticClass:"th-nodetype"},[_c('i',{staticClass:"fas fa-crown",attrs:{"title":"Node type"}})]),_c('th',{staticClass:"th-nodename"},[_c('i',{staticClass:"fas fa-terminal",attrs:{"title":"Node name"}})]),_c('th',{staticClass:"d-none d-xl-table-cell th-nodeversion"},[_c('i',{staticClass:"fas fa-laptop",attrs:{"title":"Node version"}})]),_c('th',{staticClass:"d-none d-xl-table-cell th-system"},[_c('i',{staticClass:"fas fa-keyboard",attrs:{"title":"System type"}})]),_c('th',{staticClass:"d-none d-xl-table-cell th-latency"},[_c('i',{staticClass:"fas fa-stopwatch",attrs:{"title":"Node latency"}})]),_c('th',{staticClass:"th-peers"},[_c('i',{staticClass:"fas fa-users",attrs:{"title":"Peers"}})]),_c('th',{staticClass:"d-none d-md-table-cell th-lastblock"},[_c('i',{staticClass:"fas fa-cubes",attrs:{"title":"Last block"}})]),_c('th',{staticClass:"d-none d-xl-table-cell th-peerRefresh"},[_c('i',{staticClass:"far fa-clock",attrs:{"title":"Last refresh"}})]),_c('th',{staticClass:"th-uptime"},[_c('i',{staticClass:"fa fa-flag-checkered",attrs:{"title":"% Checkpoints"}})]),_c('th',{staticClass:"d-none d-md-table-cell th-geolocal"},[_c('i',{staticClass:"fas fa-flag",attrs:{"title":"Country"}})])])])}]

export { render, staticRenderFns }